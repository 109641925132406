
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

/* Appliquer Montserrat à tous les éléments */
* {
  font-family: 'Montserrat', sans-serif;
}


.App {
  text-align: center;
  z-index: -1;

}

body {
  width: 100%;
  height: 100%;
  --color: rgba(114, 114, 114, 0.3);
  background-color: #191a1a;
  background-image: linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent),
      linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent);
  background-size: 55px 55px;
}

.advise {
  font-size: small;
  margin-top: 30%;
}

.advise2{
  font-size: small;
  margin-top: 7%;
}

.results1 {
  background-color: #fff;
  height: 30px;
  width: 330px;
  flex: 1;
  margin-top: 30px;
  border-radius: 16px;
  transition: all 1.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
  padding: 20px;
  margin-bottom: 0px;
}

.account {
  font-size: small;

}




.space {
  margin-bottom: 10px;

}

.space1 {
  margin-top: -20px;
}

strong {
  margin-right: 5px;
}



.results1-container{
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

.results-container {
  display: flex;
  flex-direction: column; /* Nouvelle propriété pour empiler verticalement */
  gap: 10px;
  align-items: center;
}

.results {
  background-color: #fff;
  height: 30px;
  width: 330px;
  flex: 1;
  border-radius: 16px;
  transition: all 1.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 40px;
  padding: 20px;

}

.results:hover {
  flex: 4;
  background-color: #b8b8b8;
  transition: all 0.8s ease;
}

.results1:hover {
  flex: 100%;
  background-color: #b8b8b8;
  transition: all 0.8s ease;
}


.img{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30%;
  margin-top: 3%;
}

.img2{
  width: 15%;
  margin-top: 7%;
}




button {
  gap: 10px;
  font-family: inherit;
  font-size: 13px;
  margin-top: 30%;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #7e97b8;
  background-color: #e0e8ef;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: rgba(255, 255, 255, 0.333);
  border-radius: 40px 40px 40px 40px;
  padding: 16px 24px 16px 28px;
  transform: translate(0px, 0px) rotate(0deg);
  transition: 0.2s;
  box-shadow: -4px -2px 16px 0px #ffffff, 4px 2px 16px 0px rgb(95 157 231 / 48%);
 }
 
 button:hover {
  color: #516d91;
  background-color: #E5EDF5;
  box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgb(95 157 231 / 48%);
 }
 
 button:active {
  box-shadow: none;
 }

#userDetails {
  margin-top: 40px;
}

#userDetails p {
  font-size: 18px;

}

#userAddress {
  font-size: x-small;
}

.error {
  color: red;
  margin-top: 20px;
}

.error1{
  margin-top: 60px;
}


.space {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}


.card {
  position: relative;
  width: 400px;
  height: 500px;
  border-radius: 14px;
  z-index: 1111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 20px 20px 60px #000000, -20px -20px 60px #000000;
  ;
}

.bg {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 390px;
  height: 490px;
  z-index: 2;
  background: rgba(255, 255, 255, .95);
  backdrop-filter: blur(24px);
  border-radius: 10px;
  overflow: hidden;
  outline: 2px solid white;
}


.blob {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #04C700;
  opacity: 1;
  filter: blur(12px);
  animation: blob-bounce 5s infinite ease;
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}